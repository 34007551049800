@media (max-width:1380px) {
  .fs-36 {
    font-size: 30px !important;
  }
  .fs-18 {
    font-size: 16px !important;
  }
  .fs-16 {
    font-size: 14px !important;
  }
  .theme-default {
    font-size: 13px;
  }
  .ant-table, .ant-table.ant-table-middle, .ant-table.ant-table-small {
    font-size: 13px;
  }
}

@media (max-width:1200px) {
  .fs-36 {
    font-size: 26px !important;
  }
  .fs-18 {
    font-size: 15px !important;
  }
  .custom-table {
    overflow-x: auto;
    &.min-width {
      min-width: 1200px;
    }
  }
  .stat-item {
    &>.m-t-sm {
      margin-top: 0 !important;
    }
  }
}

@media (max-width:1000px) {
  .fs-36 {
    font-size: 20px !important;
  }
}

@media (max-width:800px) {
  .theme-default {
    .aside-left {
      width: 50px;
      padding: 5px;
      .menu-name, .text-version {
        display: none;
      }
    }
    .main-wrapper {
      padding-left: 45px;
    }
  }
  .notifi-css {
    width: 260px;
  }
  .mega-dropdown-menu.width-650 {
    width: 300px;
    max-height: 500px;
    overflow-y: auto;
  }
  .stat-item {
    width: 100% !important;
    margin-bottom: 20px;
  }
  app-activity-box {
    &> div {
      margin-bottom: -40px;
    }
  }

  app-interview-box, app-job-box {
    .custom-table {
      margin-bottom: 30px;
    }
  }

  .frame-devide {
    .frame-right {
      width: 100%;
      margin-top: 30px;
    }
  }

  .header-page.pd-left {
    padding-left: 0 !important;
  }

  .drop-right-box {
    overflow-x: auto;

    .job-setup-right {
      overflow-x: initial;
    }
  }

  .scorecard-detail {
    width: 1200px;
  }

  .limit-modal {
    padding: 20px;
  }

  .candidate-activity, app-activity-box {
    .row.p-l-sm.m-t-lg {
      display: none;
    }

    .timeline {
      div[class^="col-"] {
        margin-bottom: 10px;
        padding-left: 15px !important;
      }

      .event {
        margin-bottom: 10px;
      }
    }
  }

  app-activity-box {
    .activity-box {
      display: none;
    }
  }

  .question-update-box {
    // .w70per {
    //   width: 100% !important;
    // }
    .col-md-8.p-l-sm {
      padding-left: 0px !important;
    }
    .question-update-action {
      &> .d-inline-flex {
        margin-bottom: 20px;
      }
    }
  }

  .skill-label {
    margin-bottom: 5px;
    display: inline-block;
  }

  .profile-box {
    padding-left: 0 !important;
    margin-left: 0 !important;

    .btn.btn-primary.m-t-sm {
      position: absolute;
      top: -65px;
      right: 0;
    }
  }

  app-interview-box {
    nz-pagination {
      float: left;
    }
  }
}