/* Variables */

// $background: #252827;
$color-light: white;
$spacing: 30px;
$radius: 4px;

$date: 120px;
$dotborder: 4px;
$dot: 6px;
$line: 2px;

/* Timeline */

.timeline {
  border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;    
  color: fade(white, 80%);
  font-family: $font-lato;  
  letter-spacing: 0.5px;   
  position: relative;
  line-height: 1.4em;  
  padding: $spacing;   
  list-style: none;
  text-align: left;  
  margin-left: 7px;
  padding-top: 15px;
  padding-right: 0;
  
  .event {
    border-bottom: 1px dashed fade($color-light, 10%);
    position: relative;
    // margin-bottom: 10px;
    min-height: 42px;
    padding: 6px 10px;
    background-color: #FAFAFA;
    border: 1px solid #FAFAFA;
    &>.row {
      min-height: 30px;
      &>div {
        &:last-child {
          &> a {
            &:last-child {
              margin-right: 0 !important;
            }
          }
        }
      }
      .col-md-3 {
        line-height: 22px;
      }
    }

    &:last-of-type { 
      // padding-bottom: 0;
      // border: none; 
      margin-bottom: 0;      
    }

    &:before, &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: -32px;    
      // left: ((($date * 0.6) + $spacing + $line + $dot + ($dotborder * 2)) * 1.5) * -1;    
      color: fade($color-light, 40%);    
      content: attr(data-date);
      text-align: right;
      font-weight: 100;    
      font-size: 0.9em;

      height: 100%;
      width: 2px;
      border-right: 2px dotted #B3B3B3;
      // bottom: -18px;
      top: 50%;
      // top: inherit;
      // min-width: $date;
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    &:after {
      box-shadow: 0 0 0 $dotborder fade($green-darker,100%);    
      left: ($spacing + $line + ($dot * 0.35)) * -1;        
      background: #808080;    
      border-radius: 50%;  
      height: $dot;
      width: $dot;
      content: "";
      top: 50%;
      transform: translateY(-50%);
      // top: 18px;
      // top: 5px;
    }

    &.active {
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
      &::after {
        left: -37px;  
        background-color: #5A9785;
        border: 3px solid #9BD6A8;
        width: 12px;
        height: 12px;
      }
      &::before {
        border-right: 2px dotted #38AD51;
      }
    }
  }

  &.have-line {
    .event {
      background-color: transparent;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: transparent;
      min-height: 38px;
      padding: 3px 10px;
      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
}