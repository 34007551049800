.blank {
  &-head {
    margin-bottom: 60px;
  }

  &-page {
    height: 100vh;
    background-image: url('/assets/images/bg/bg-3.jpg');
    // background-color: #dee2e6;
    // background-repeat: repeat;
    // background: linear-gradient(to top right,#195762,#b1c9ce);
    // background: url('/assets/images/bg/bg-1.png'), linear-gradient(to top right,#195762,#b1c9ce);
  }

  &-box {
    width: 100%;
    max-width: 450px;
    text-align: center;
    color: #000000;
    padding: 22px;
    .form-group {
      text-align: left;
    }

    &.max-600 {
      max-width: 600px;
    }
    &.max-700 {
      max-width: 700px;
    }
  }

  &-title {
    font-size: 20px;
  }

  &-description {
    opacity: 0.5;
    font-weight: 600;
  }

  &-forgot {
    font-weight: 600;
    display: block;
  }

  &-btn {
    min-width: 85px;
  }

  &-signup {
    font-weight: 600;
    margin-left: 10px;
  }

  &-logo {
    // max-height: 100px;
    max-height: 65px;
  }
}
