body {
  margin: 0;
  font-family: $font-lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  // color: #3F4254;
  text-align: left;
  background: #eef0f8;
  // font-family: $font-primary;
  // font-size: $size-normal;
}
.ant-back-top {
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  right: 10px;
  bottom: 20px;
  width: 40px;
  height: 40px;
}
.bg-white {
  background-color: #ffffff !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-mid {
  vertical-align: middle;
}

a {
  cursor: pointer;
}

.z-1 {
  z-index: 1;
  position: relative;
}

.z-3 {
  z-index: 3;
  position: relative;
}

.shadow-box {
  box-shadow: 0px 4px 10px #476469;
  background-color: #ffffff;
}
// input:not(:placeholder-shown) {
//   background-color: #ffffff !important;
// }
.count-down {
  .coming-box {
    color: whitesmoke;
    float: left;
    width: 33%;
  }

  .counter-number {
    font-size: 32px;
    font-weight: 700;
  }

  .counter-number span {
    font-size: 15px;
    font-weight: 400;
    display: block;
  }
}
.not-apply-bg {
  // input:not(:placeholder-shown) {
  //   background-color: inherit !important;
  // }
  .ant-picker-suffix {
    position: relative;
    top: -2px;
  }

  .ant-picker-clear {
    background-color: #f7f6f3;
    margin-top: -2px;
  }
}

.min-width-80 {
  min-width: 80px;
}

.max-width-120 {
  max-width: 120px;
}

.max-width-300 {
  max-width: 300px;
}

.min-width-150 {
  min-width: 150px;
}

.width-130 {
  width: 130px;
}

.width-150 {
  width: 150px;
}

.width-250 {
  width: 250px;
}

.width-200 {
  width: 200px;
}

.width-300 {
  width: 300px;
}

.width-350 {
  width: 350px;
}

.width-500 {
  width: 500px;
}

.width-550 {
  width: 550px;
}

.width-650 {
  width: 650px;
}

.width-850 {
  width: 850px;
}

.width-900 {
  width: 900px;
}

.w20per {
  width: 20% !important;
}

.w30per {
  width: 30% !important;
}

.w40per {
  width: 40% !important;
}

.w50per {
  width: 50% !important;
}

.w60per {
  width: 60% !important;
}

.w70per {
  width: 70% !important;
}

.w80per {
  width: 80% !important;
}

.w90per {
  width: 90% !important;
}

.w95per {
  width: 95% !important;
}

.height-200 {
  height: 200px;
}

.height-300 {
  height: 300px;
}

.br-20 {
  border-radius: 20px;
}

.green-light {
  background: rgba(56, 173, 81, 0.1);
}

.noti-dot {
  &::after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $red-light;
    position: absolute;
    content: '';
  }
}

.skill-label {
  background: rgba(25, 87, 98, 0.2);
  border-radius: 20px;
  padding: 3px 10px 5px 10px;
}

.modal-fullscreen {
  &.aluha-modal.modal {
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }
}

.border-gray {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.btn {
  font-size: 12px;
  font-family: $font-opensans;
  border-radius: 3px;
  font-weight: 600;
  padding: 6px 12px;
  color: #000000;
  box-shadow: none;
  line-height: 16px;
  border: 1px solid #000000;
  min-width: 64px;
  font-weight: 600;
  // max-height: 30px;

  // &::after {
  //     display: none;
  // }

  .icon {
    margin-right: 6px;
    position: relative;
    top: -1px;

    &.last {
      margin-right: 0;
      margin-left: 6px;
    }
  }

  &-name {
    position: relative;
    top: -8px;
  }

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  &.btn-primary {
    color: #ffffff;
    background-color: $green-darker;
    border-color: $green-darker;

    &:hover {
      background-color: $green-darker;
      border-color: $green-darker;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      box-shadow: none !important;
    }

    &:active {
      background-color: $green-darker;
      border-color: $green-darker;
    }
  }

  &.btn-dropdown {
    padding-right: 42px;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      content: '';
      height: 100%;
      border-left: 1px solid rgb(255 255 255 / 64%);
      right: 27px;
    }

    &::after {
      right: 9.5px;
      content: '\f078';
      font-family: 'Font Awesome 5 Free';
      border: 0;
      font-size: 9px;
    }

    &.outline {
      background-color: transparent;
      color: $black;
      border-color: $black;

      &::before {
        border-left: 1px solid $black;
      }
    }

    &.none-outline {
      padding-right: 28px;

      &::before {
        opacity: 0;
      }

      &::after {
        font-size: 11px;
        right: 11px;
      }
    }

    &.small {
      padding-left: 8px;
      padding-top: 1px;
      padding-bottom: 1px;
      background-color: #d7efdc !important;

      &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.none-outline {
    background-color: transparent;
    color: $black;
    border-color: transparent;

    &:hover {
      background-color: rgba(25, 87, 98, 0.1);
      box-shadow: none;
    }
  }

  &.none {
    background-color: transparent;
    color: $black;
    border-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
    min-width: auto;
  }

  &:disabled {
    box-shadow: none !important;
    cursor: initial;
  }

  &.btn-small {
    padding: 3px 8px !important;
  }

  &-white {
    background-color: #ffffff;
    color: $green-darker !important;
    border: 1px solid $green-darker !important;
  }

  &.btn-white {
    background-color: #ffffff;
    color: $black !important;
    border: 1px solid $black !important;
  }
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $green-darker;
  border-color: $green-darker;
}

.score-answer {
  // padding-bottom: 10px;

  &-avt {
    width: 42px;
  }

  &-right {
    width: calc(100% - 42px);
  }

  &-item {
    margin-bottom: 20px;
  }
}

.top-1 {
  position: relative;
  top: -1px;
}

.top-2 {
  position: relative;
  top: -2px;
}

.top-3 {
  position: relative;
  top: -3px;
}

.top-4 {
  position: relative;
  top: -4px;
}

.top-5 {
  position: relative;
  top: -5px;
}

.top-6 {
  position: relative;
  top: -6px;
}

.top-15 {
  // position: relative;
  top: -15px !important;
}

.top-12 {
  position: relative;
  top: -12px;
}

.top_1 {
  position: relative;
  top: 1px;
}

.top_2 {
  position: relative;
  top: 2px;
}

.top_3 {
  position: relative;
  top: 3px;
}

.top_4 {
  position: relative;
  top: 4px;
}

.top_5 {
  position: relative;
  top: 5px;
}

.opacity {
  opacity: 0 !important;
}

.ver-top {
  vertical-align: top;
}

.modal-md {
  max-width: 950px;
}

.required {
  &::after {
    content: '*';
    color: #ff0000;
    margin-left: 1px;
  }
}

.form-group {
  position: relative;
  margin-bottom: 1rem;

  &.err {
    input {
      background: transparent;
      padding-right: 35px;
    }

    &::after {
      content: 'priority_high';
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $red;
      color: #ffffff;
      font-family: 'Material Icons';
      text-align: center;
    }
  }

  label {
    font-size: $size-alt1;
    font-family: $font-lato;
    // opacity: 0.5;
    color: $black-light;
    margin-bottom: 5px;
    font-weight: 600;
  }

  &.small {
    .ant-input-affix-wrapper {
      height: 30px;
      font-size: 12px;
    }

    .ant-input {
      font-size: 12px;
    }

    &.fix-pd {
      .ant-input-affix-wrapper {
        padding: 3px 12px;
      }
    }
  }

  .form-control,
  .ant-input-affix-wrapper {
    // background: $gray-light;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 9px 12px;
    font-size: 14px;
    line-height: 15px;
    box-shadow: none !important;
    color: #000000;

    &.have-icon {
      padding-right: 45px;
    }

    &.only-text {
      border: 0 !important;
      border-left: 4px solid #195762 !important;
      border-radius: 0;
    }

    &.merge-left {
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.merge-right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &-icon {
      position: absolute;
      right: 10px;
      top: 50%;
      line-height: 12px;

      &.transform {
        transform: translateY(-50%);
      }

      &.inherit {
        line-height: 25px;
      }
    }

    &.small {
      height: 30px;
      font-size: 12px;
    }

    &:focus {
      background: #ffffff;
      border: 1px solid #000000;
    }

    &:hover {
      border: 1px solid $green-darker;
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.05);
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: #9e9e9e;
    }

    &::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: rgba(0, 0, 0, 0.5);
    }

    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(0, 0, 0, 0.5);
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(0, 0, 0, 0.5);
    }

    &::placeholder {
      /* Most modern browsers support this now. */
      color: rgba(0, 0, 0, 0.5);
    }
  }
}

.item-mention {
  &.active {
    background-color: rgb(25 87 98 / 0.1);
    color: #000000;
  }
}

.avt-circle {
  // border: 1px solid #eeeeee;
  // border: 2px solid rgba(0, 0, 0, 0.1);
  background: #d9d9d9;
  border-radius: 50%;

  &.smaller {
    width: 16px;
    height: 16px;
  }

  &.small {
    width: 24px;
    height: 24px;
  }

  &.normal {
    width: 30px;
    height: 30px;
  }

  &.big {
    width: 100px;
    height: 100px;
  }

  &.border {
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
  }
}

.card {
  &-box {
    position: relative;
    max-width: 276px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 11px;
    font-family: $font-lato;
    min-height: 48px;

    &:hover {
      .remove-over {
        display: inherit;
      }
    }

    &.move {
      cursor: move;
    }

    &.active {
      background-color: $green-darker;
      color: #ffffff;

      .card-action {
        color: #ffffff;
      }
    }

    &.disabled {
      background-color: #fafafa;
      cursor: default;
      // pointer-events: none;

      .card-name {
        color: #a9a6a6;
      }

      .checkmark {
        border: 2px solid #cacaca;
      }

      .card-action {
        &:hover {
          color: #000000;
        }
      }

      .stage__drag {
        cursor: default;
      }
    }
    &.special {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  &-des {
    font-size: 12px;
    color: $black-light;
  }

  &-action {
    position: absolute;
    right: 12px;
    top: 12px;
    color: $black-light;
    z-index: 1;

    .container-check {
      top: -13px;
      right: 20px;
    }
  }

  &-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 90%;

    &.underline {
      &:hover {
        color: $green-darker !important;
        text-decoration: underline;
        // text-shadow: 1px 2px 2px #d1d1d1;
      }
    }
  }
}
user-avatars + .card-name {
  // max-width: 160px;
  max-width: 73%;
}
.hide-drag-holder {
  .cdk-drag-placeholder {
    opacity: 0;
  }
}

.min-height {
  min-height: 10px;
}

.text-under {
  text-decoration: underline;
}

.assign-over {
  position: absolute;
  left: 70px;
  top: 50px;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  width: 240px;
  opacity: 0;
  transition: all 0.5s;
}

.ant-dropdown-menu-item {
  &:hover {
    .assign-over {
      opacity: 1;
    }
  }
}

.container-check {
  position: relative;
  cursor: pointer;

  &.disabled {
    &::after,
    .s_slider::after {
      opacity: 0;
      display: none;
    }
    cursor: not-allowed;
    .s_slider {
      cursor: not-allowed;
    }
  }

  &::after {
    content: '';
    width: 40px;
    height: 40px;
    background-color: rgba(25, 87, 98, 0.1);
    position: absolute;
    left: -11px;
    top: -11px;
    border-radius: 50%;
    opacity: 0;
    transition: ease-in-out 0.5s;
  }

  input[type='radio'] ~ .radiomark {
    top: -1px;
    left: -1px;
  }

  &.none-bg {
    &::after {
      background-color: transparent;
    }
  }

  &.disabled {
    &::after {
      cursor: not-allowed;
    }
  }

  &:hover {
    &:not(.switches) {
      &::after {
        opacity: 1;
      }
    }

    .s_slider {
      &::after {
        opacity: 1;
      }
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      & ~ .checkmark {
        background-color: #000000;

        &::after {
          display: block;
        }
      }

      & ~ .radiomark {
        &::after {
          display: block;
        }
      }

      & + .s_slider {
        background-color: rgba(25, 87, 98, 0.5);

        &::before {
          background-color: $green-darker;
          -webkit-transform: translateX(26px);
          -ms-transform: translateX(26px);
          transform: translateX(26px);
        }

        &::after {
          left: initial;
          right: -16px;
        }
      }
    }

    &:disabled {
      & ~ .checkmark,
      & ~ .radiomark,
      & ~ .s_slider {
        opacity: 0.5;
      }
    }
  }

  &.not-blur {
    input {
      &:disabled {
        & ~ .checkmark,
        & ~ .radiomark,
        & ~ .s_slider {
          opacity: 1;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 2px solid #000000;
    border-radius: 3px;
    background-color: transparent;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 4px;
      top: 0px;
      width: 6px;
      height: 11px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .radiomark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid #000000;

    &::after {
      content: '';
      position: absolute;
      display: none;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #000000;
    }
  }

  .s_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &::before {
      border-radius: 50%;
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: -4px;
      bottom: -4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: '';
      width: 40px;
      height: 40px;
      background-color: rgba(25, 87, 98, 0.1);
      position: absolute;
      left: -14px;
      top: -14px;
      border-radius: 50%;
      opacity: 0;
      transition: ease-in-out 0.5s;
    }
  }

  &.switches {
    width: 36px;
    height: 12px;
  }

  &.small {
    .checkmark {
      width: 15px;
      height: 15px;

      &::after {
        left: 3px;
        top: -1px;
        width: 5px;
        height: 10px;
      }
    }

    .radiomark {
      width: 15px;
      height: 15px;

      &::after {
        top: 2px;
        left: 2px;
        width: 7px;
        height: 7px;
      }
    }

    &::after {
      width: 35px;
      height: 35px;
    }
  }

  &.center-v {
    top: 13px;
  }

  &.center-row {
    top: -13px;
  }
}

.card-action {
  .container-check {
    &.disabled {
      input[type='checkbox'],
      .checkmark {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}

.dropdown-menu {
  font-size: $size-normal;
  padding: 6px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-top: 5px;

  &.no-padding {
    padding: 0;
  }

  &.limit {
    width: 240px;
  }

  .dropdown-item {
    font-family: $font-lato;
    font-size: $size-normal;
    outline: none;
    padding: 3px 12px 6px;

    &:active,
    &:focus,
    &:hover {
      background-color: rgb(25 87 98 / 0.1);
      color: #000000;
    }
  }

  .dropdown-description {
    display: block;
    font-size: $size-alt2;
    line-height: 14px;
    color: rgb(0 0 0 / 0.5);
    white-space: normal;
  }
}

.point-red {
  height: 10px;
  width: 10px;
  background-color: $red;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: 3px;
}

.box-shadow {
  position: relative;
  z-index: 3;
  background: #ffffff;
  -webkit-box-shadow: 1px 1px 10px 0px $green-dark;
  -moz-box-shadow: 1px 1px 10px 0px $green-dark;
  box-shadow: 1px 1px 10px 0px $green-dark;
  // -webkit-box-shadow: 1px 1px 6px 0px rgba(25,87,98,1);
  //   -moz-box-shadow: 1px 1px 6px 0px rgba(25,87,98,1);
  //   box-shadow: 1px 1px 6px 0px rgba(25,87,98,1);
}

.tox-tinymce {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 3px !important;
}

.icon-star {
  background-image: url('/assets/images/icon/ico-start.svg');
  width: 12px;
  height: 12px;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 1px;
  margin-left: 3px;
}

.backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // background-color: rgba($black, 0.6);
  background-color: rgba(25, 87, 98, 0.7);
  transition: ease 0.5s;
  z-index: 2;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }
}

.people-list {
  &.show-enable {
    .cdk-drop-list {
      position: relative;
      z-index: 1;
      background: #ffffff;
      -webkit-box-shadow: 1px 1px 10px 0px $green-dark;
      -moz-box-shadow: 1px 1px 10px 0px $green-dark;
      box-shadow: 1px 1px 10px 0px $green-dark;
    }

    .text-description {
      position: relative;
      z-index: 1;
      color: #ffffff;
    }
  }
}

.upload-custom {
  background: #f7f6f3;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 18px 22px;

  &.have-file {
    background: #ffffff;
    border: 1px solid #000000;
  }
}

.merge-left {
  .ant-picker {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.merge-right {
  .ant-picker {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.answer-reject {
  border: 1px solid rgba(0, 0, 0, 0.1);
  // border-bottom: 1px solid transparent;

  &-item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px;

    &:first-child {
      border-top: 0;
    }
  }

  .container-check {
    margin-right: 20px;
    margin-top: 3px;
    float: right;
  }
}

// .row {
//   .col-md-6 {
//     &:last-child {
//       .answer-reject {
//         border-bottom: 1px solid rgba(0, 0, 0, 0.1);
//       }
//     }
//   }
// }

.custom-salary-type {
  position: absolute;
  width: 95px;
  right: 2px;
  top: 30px;

  .ant-select-selector {
    border: 0 !important;
    height: 30px !important;
    text-align: center;
  }

  .form-control-icon {
    right: 78px;
  }

  .ant-select-dropdown {
    min-width: auto;
  }

  .ant-select-selection-search {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    top: -4px !important;
  }

  .ant-select-selection-item {
    padding-left: 10px !important;
    position: relative;
    top: -1px;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: transparent;
  }

  .ant-select-disabled .ant-select-arrow {
    .select-icon {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}

.has-error {
  .custom-salary-type {
    top: 29px;

    .ant-select {
      border: 1px solid transparent !important;
    }
  }
}

.right-custom-type {
  right: 100px !important;
}

.schedule-avatar {
  max-width: 95px;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
}

.schedule-top {
  .mat-icon-outline {
    display: none;
    position: absolute;
    bottom: 6px;
    left: 35px;
    font-size: 16px;
    background-color: #ffffff;
    border-radius: 50%;

    &.show {
      display: inherit;
    }
  }
}

.btn-comment {
  position: absolute;
  z-index: 1;
  bottom: 8px;
  right: 10px;
  color: #fafafa !important;
  background-color: $green-darker !important;
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
  max-height: 32px !important;
}

.icon-yes {
  transform: rotate(180deg) scaleX(-1);
}

.nav {
  &-tabs {
    border: 0;

    .nav-item {
      min-width: 138px;
      text-align: center;
    }

    &.menu-line {
      .nav-item {
        min-width: 120px;
        text-align: center;
      }
    }
  }

  &-link {
    border: 0;
    border-color: #000000 !important;
    border-bottom: 2px solid #000000;
    color: #000000 !important;

    &:hover {
      color: #000000;
    }

    &.active,
    &.active-custom {
      border-bottom: 6px solid #000000;
      font-weight: 600;
    }
  }
}

.ant-tabs-nav {
  .ant-tabs-tab {
    min-width: 120px;
    text-align: center;
    color: #000000 !important;

    &:hover {
      color: #000000;
    }
  }

  .ant-tabs-ink-bar {
    background-color: rgba(0, 0, 0, 0.85);
    height: 6px;
  }

  .ant-tabs-tab-active {
    border-bottom: 6px solid #000000;
    font-weight: 600;
  }
}

.ant-tabs-bar {
  border-bottom: 2px solid #000000;
}

.nav-tabs .nav-link {
  border: 0;
  // border-color: #000000 !important;
  border-bottom: 2px solid #000000;

  &.active,
  &.active-custom {
    border-bottom: 6px solid #000000;
  }
}

.notifi-css {
  width: 350px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 50%;
    text-align: center;
    padding: 10px;
  }

  .ant-tabs-tab-active {
    border-bottom: 6px solid #000000 !important;
  }

  .notification {
    &-item {
      font-family: 'Lato';
      padding: 4px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;

      &.custom-left {
        .notification-left {
          padding-bottom: 0;
          position: relative;
          top: 8px;
        }
      }
    }

    &-left {
      padding: 14px;
      width: 60px;
    }

    &-right {
      width: calc(100% - 60px);
      padding-right: 10px;
    }

    &-date {
      font-size: 10px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.5);
      width: calc(100% - 60px);
      padding-right: 10px;

      &.m-l-60 {
        margin-left: 60px;
      }
    }

    &-list {
      max-height: 250px;
      overflow-y: auto;
    }
  }

  .tab-footer {
    display: flex;
    align-items: center;
    background-color: #f7f6f3;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
}

.form-answer {
  .ant-radio-wrapper,
  .ant-checkbox-group-item {
    font-size: 14px;
    color: #000000;
    font-weight: normal;
  }

  .ant-checkbox-group-item {
    display: block;
    margin-bottom: 22px;
  }
}

.border-error {
  border: 1px solid #ee4e4e !important;
}

.err-required {
  .ant-checkbox-inner,
  .ant-radio-inner {
    border: 1px solid #ee4e4e !important;
  }
}

.expand-more {
  transition: all 0.5s;

  &.active {
    transform: rotate(180deg);
  }
}

// Ripple
.pinkBg {
  background-color: $green-darker !important;
  background-image: linear-gradient(90deg, $green, $green-darker);
}

.pink-err {
  background-color: #ee4e4e !important;
  background-image: linear-gradient(90deg, #f32121, #ee4e4e);
}

.ripple-btn {
  height: 20px;
  width: 20px;
  position: absolute;
  z-index: 1;
  // left: 12px;
  // top: 8px;

  i {
    font-size: 15px;
    color: #f5f5f5;
    text-shadow: 1px 1px 4px #000;
  }

  .ripple {
    position: absolute;
    width: 40px;
    height: 40px;
    left: -64%;
    top: -50%;
    z-index: -1;
    opacity: 0;
    border-radius: 100px;
    -webkit-animation: ripple 1.8s infinite;
    animation: ripple 1.8s infinite;
  }

  .ripple:nth-child(2) {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
  }

  .ripple:nth-child(3) {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1180px;
  }
}

.fs-140 {
  font-size: 140px;
}

.mention-box {
  p {
    // display: inline-block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
  }
}

.small-popup {
  top: 20%;

  .modal-dialog {
    max-width: 340px;
  }

  .modal-content {
    // min-height: 200px;
  }

  &.md {
    .modal-dialog {
      max-width: 450px;
    }
  }
}

.custom-err {
  .text-error {
    height: 15px;
    overflow: hidden;
    display: inline-block;
    width: 110%;
  }
}

.text-err {
  color: #ee4e4e;
  font-size: 12px;
  float: left;
  margin-bottom: 15px;
}

.salary-fix {
  min-width: 120px;
  display: inline-block;
}

.form-group {
  ul {
    list-style: none;
  }
}

.show-dote {
  ul {
    list-style: inherit;
    padding-left: 18px;
    list-style-type: inherit;
  }
}

.disable-tick {
  cursor: not-allowed;
  color: $black-light;

  .checkmark,
  .select-time-picker .ant-picker-input > input {
    border-color: $black-light;
  }

  input:checked ~ .checkmark {
    background-color: $black-light;
    border-color: $black-lighter !important;
  }

  .container-check,
  .select-time-picker .ant-picker-input > input {
    cursor: not-allowed;
    pointer-events: none;
    color: $black-light;
  }
  .ant-picker-suffix {
    color: $black-light;
  }
  .ant-picker {
    border-bottom: 1px solid $black-light;
    pointer-events: none;
  }
}

.show-editer {
  p {
    margin-top: 0.5rem;
    margin-bottom: 0.875rem;
  }
  pre {
    white-space: inherit;
  }
  ul {
    list-style: initial;
    padding-left: 16px;
  }
}

.show-guideline {
  p {
    margin-top: 0;
  }
}

.special {
  &.disabled {
    color: #a9a6a6;

    .avt-circle {
      position: relative;
      overflow: hidden;
      display: inline-flex;
      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgb(197 197 197 / 40%);
        z-index: 1;
      }
    }

    .point-overrange {
      position: absolute;
      z-index: 1;
      // top: 1px;
      // left: 1px;
      opacity: 0.5;

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.scrore-card-miss {
  min-width: 250px;
}

.modal-backdrop {
  background-color: rgba(25, 87, 98, 0.7);
  &.show {
    opacity: 1;
  }
}

.cdk-drag-preview {
  .remove-over {
    display: none;
  }
}
// .custom-salary-type {
//   .ant-select-arrow {
//     top: 40%;
//   }
// }
.manage-stage {
  .modal-content {
    width: 500px;
    left: initial !important;
  }
  .timeline {
    .event {
      background-color: transparent !important;
      border: 0 !important;
      padding-top: 0 !important;
      &::before {
        top: 0;
      }
      &::after {
        top: 0;
      }
      &__stage {
        position: relative;
        top: -10px;
      }

      &.current {
        &::before {
          border-right: 2px dotted rgba(56, 173, 81, 0.3);
        }
        &::after {
          background-color: #38ad51;
        }
      }
      &.non-active {
      }
    }
  }
  &.modal-static {
    .modal-dialog {
      transform: none !important;
    }
  }
}

.opacity-5 {
  opacity: 0.5;
}

.cursor-block {
  cursor: not-allowed;
}

.fix-icon {
  max-width: 30px;
  overflow: hidden;
}

.min-w350 {
  min-width: 350px;
}

nz-table,
nz-select,
nz-date-picker,
nz-month-picker,
nz-range-picker,
nz-tree-select,
nz-time-picker,
.ant-input-number,
nz-week-picker {
  width: 100%;
}
