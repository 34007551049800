.app-breadcrumb {
    list-style: none;
    padding-left: unset;
    height: 36px;
    display: inline-flex;
    align-items: center;
    &-item {
        display: inline-block;
        margin: 0 6px 0 0px;
        vertical-align: middle;
        color: $black-light;
        font-weight: 600;
        min-height: 24px;
        &:hover {
            color: $color-primary;
        }
        &::after {
            content: 'keyboard_arrow_right';
            font-family: 'Material Icons';
            font-size: 24px;
            vertical-align: middle;
            margin-left: 8px;
            line-height: 0;
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        &.not-arr {
            &::after {
                display: none;
            }
        }
        &.active {
            color: #000000;
        }
        &.over {
            color: $color-primary;
        }
        &.disabled {
          .app-breadcrumb-link {
            color: $black-light !important;
            cursor: not-allowed;
          }
        }
    }
    &-link {
        display: inline-block;
        &:hover {
            color: $color-primary;
        }
        &.disabled {
          color: $black-light !important;
          cursor: not-allowed;
        }
    }
}