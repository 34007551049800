.theme-default {
  .custom-table {
    .ant-table {
      color: #000000;
    }
    th,
    td {
      padding: 3px 6px;
    }
    th {
      background-color: transparent;
      font-size: $size-alt1;
      font-weight: 600;
      color: $black-light;
      padding-bottom: 10px;
    }
  
    &.border-head {
      thead {
        th {
          padding-top: 8px;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          &:first-child {
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }
          &:last-child {
            border-right: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  
    tr {
      td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:first-child {
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          padding-left: 10px;
        }
        &:last-child {
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          padding-right: 5px;
        }
      }
  
      th {
        &:first-child {
          padding-left: 10px;
        }
        &:last-child {
          padding-right: 5px;
        }
      }
    }
  
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev,
    .ant-pagination-next,
    .ant-pagination-prev,
    .ant-pagination-item {
      min-width: 26px;
      height: 26px;
      line-height: 20px;
    }
  
    .ant-pagination-item-active a,
    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
      color: $color-primary;
    }
  
    .ant-pagination-item-active {
      border-color: $color-primary;
    }
  
    .ant-pagination-item {
      line-height: 24px;
    }
  
    .table-title {
      padding: 4px 0px;
      display: inline-block;
      &:hover {
        text-decoration: underline !important;
        color: $color-primary;
      }
      &.not-line {
        &:hover {
          text-decoration: none !important;
          color: inherit !important;
        }
      }
    }
  
    @media screen and (max-width: 1350px) {
      .table-icon {
        position: absolute;
        top: initial;
      }
      th,
      td {
        padding: 3px 14px;
      }
  
      th {
        padding-bottom: 6px;
        vertical-align: bottom;
      }
    }
  }
}