.job {
  &-create {
    padding-left: 15%;
    padding-right: 15%;

    &.custom-pipe {
      padding-right: 2%;

      .main-content {
        max-width: initial;
      }
    }

    &-frame {
      height: calc(100vh - 78px);
      overflow-y: auto;
      scrollbar-width: thin;
    }

    .main-content {
      padding: 15px;
      max-width: 980px;
      height: inherit;
      overflow-x: hidden;
    }
  }
}

.modal-frame {
  height: calc(100vh - 78px);
  overflow-y: auto;
  scrollbar-width: thin;
  font-family: $font-lato;
}

.application-form {
  max-width: 600px;

  & > .align-items-center:not(.not-bg) {
    padding-top: 5px;
    padding-bottom: 2px;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}

.question-update,
.question-update-box {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f7f6f3;
  margin-top: 10px;
  margin-bottom: 15px;
}

.job-setup {
  &-left {
    width: 276px;
    display: inline-block;
    margin-right: 34px;
  }

  &-right {
    width: calc(100% - 310px);
    display: inline-block;
    vertical-align: top;
    overflow-x: auto;
    scrollbar-width: thin;
    // Can clear scroll
    // height: calc( 100vh - 30px);
    // height: calc( 100vh - 35px);
    height: calc(100vh - 230px);
    /* custom scroll bar */

    &::-webkit-scrollbar {
      width: 5px;
      height: 10px;
    }
  }
}

.pipeline {
  &-head {
    font-family: $font-primary;
    font-weight: 600;
    padding: 8px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    margin-bottom: 25px;
    color: $black-light;
    width: 2082px;

    &.score {
      width: 100%;
    }
  }

  &-title {
    border-radius: 3px;
    padding: 3px 6px;
    display: inline-flex;
    align-items: center;
    margin-bottom: 40px;
  }

  &-col {
    display: inline-block;
    width: 276px;
    margin-right: 25px;
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }
  }

  &-list {
    // Can clear scroll
    // height: calc(100vh - 205px);
    height: calc(100vh - 395px);
    overflow-y: auto;
    padding-right: 5px;
    margin-bottom: 20px;
    scrollbar-width: thin;

    /* custom scroll bar */

    &::-webkit-scrollbar {
      width: 5px;
      height: 3px;
    }
  }

  &-box {
    width: 2082px;
  }
}

.scorecard {
  &-box {
    // Can clear scroll
    height: calc(100vh - 496px);
    // height: calc(100vh - 472px);
    // height: calc(100vh - 270px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}

.clear-padding {
  .job-create {
    padding: 0;
    margin-bottom: 30px;
  }
}

.choose {
  &-box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    cursor: pointer;
  }

  &-content {
    height: 250px;
    padding: 24px;
  }

  &-footer {
    padding: 13px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-family: $font-primary;
  }
}

.frame-pipe {
  // width: calc(100% - 200px);
  width: 85%;
  vertical-align: top;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  height: calc(100vh - 280px);

  &::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }

  .pipeline-list {
    height: calc(100vh - 450px);
  }

  &.full {
    width: 100%;
    height: calc(100vh - 200px);
    overflow-y: hidden;

    .pipeline-list {
      height: calc(100vh - 365px);
    }
  }
}
