@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/opensans/OpenSans-Bold.eot");
  src: url("../../fonts/opensans/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/opensans/OpenSans-Bold.woff2") format("woff2"),
    url("../../fonts/opensans/OpenSans-Bold.woff") format("woff"),
    url("../../fonts/opensans/OpenSans-Bold.ttf") format("truetype"),
    url("../../fonts/opensans/OpenSans-Bold.svg#OpenSans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/opensans/OpenSans-Regular.eot");
  src: url("../../fonts/opensans/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/opensans/OpenSans-Regular.woff2") format("woff2"),
    url("../../fonts/opensans/OpenSans-Regular.woff") format("woff"),
    url("../../fonts/opensans/OpenSans-Regular.ttf") format("truetype"),
    url("../../fonts/opensans/OpenSans-Regular.svg#OpenSans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("../../fonts/opensans/OpenSans-SemiBold.eot");
  src: url("../../fonts/opensans/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/opensans/OpenSans-SemiBold.woff2") format("woff2"),
    url("../../fonts/opensans/OpenSans-SemiBold.woff") format("woff"),
    url("../../fonts/opensans/OpenSans-SemiBold.ttf") format("truetype"),
    url("../../fonts/opensans/OpenSans-SemiBold.svg#OpenSans-SemiBold") format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("../../fonts/lato/Lato-Bold.eot");
  src: url("../../fonts/lato/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/lato/Lato-Bold.woff2") format("woff2"),
    url("../../fonts/lato/Lato-Bold.woff") format("woff"),
    url("../../fonts/lato/Lato-Bold.ttf") format("truetype"),
    url("../../fonts/lato/Lato-Bold.svg#Lato-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  src: url("../../fonts/lato/Lato-Regular.eot");
  src: url("../../fonts/lato/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../fonts/lato/Lato-Regular.woff2") format("woff2"),
    url("../../fonts/lato/Lato-Regular.woff") format("woff"),
    url("../../fonts/lato/Lato-Regular.ttf") format("truetype"),
    url("../../fonts/lato/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/material/material.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/material/material-outline.woff2") format('woff2');
  font-display: block;
}

.mat-icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  vertical-align: bottom;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  
  &.small {
    font-size: 16px;
    line-height: 24px;
  }
}

.mat-icon-outline {
  font-family: 'Material Icons Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  
  &.small {
    font-size: 16px;
    line-height: 24px;
  }
  
  &.line-height {
    line-height: 17px;
  }
}

.mat-icon7 {
  &::after {
    content: url('../../images/icon/number7.svg');
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 3px;
  }
}
