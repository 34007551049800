.cdk-overlay-container {
  z-index: 1111;
}

.ant-picker-focused,
.ant-picker:hover {
  border: 1px solid $green-darker;
}

.ant-avatar {
  width: 30px;
  height: 30px;

  &.small {
    width: 24px !important;
    height: 24px !important;
    line-height: 24px !important;
  }
}
.answer-question .ant-checkbox-inner {
  display: inline-block !important;
}
.ant-dropdown-menu {
  font-size: $size-normal;
  padding: 6px 0px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;

  &.no-padding {
    padding: 0;
  }

  &.limit {
    width: 240px;
  }

  .ant-dropdown-menu-item {
    font-family: $font-lato;
    font-size: $size-normal;
    outline: none;
    // padding: 3px 12px 6px;
    padding: 4px 12px 5px;

    &:active,
    &:focus,
    &:hover {
      background-color: rgb(25 87 98 / 0.1);
      color: #000000;
    }
  }

  .dropdown-description {
    display: block;
    font-size: $size-alt2;
    line-height: 14px;
    color: rgb(0 0 0 / 0.5);
    white-space: normal;
  }
}

.ant-dropdown-menu-item-selected {
  color: #000000;
}

.ant-picker {
  background-color: #ffffff;
  // background-color: #F7F6F3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 7.5px 12px;
  font-size: 14px;
  line-height: 15px;
  box-shadow: none !important;
}

.ant-picker-input > input {
  line-height: initial;
  // background-color: #F7F6F3 !important;
  background-color: #ffffff !important;
  font-family: $font-lato;
  font-size: 14px;
  color: #000000;
}

.ant-picker-cell .ant-picker-cell-inner,
.ant-picker-content th {
  font-size: 14px;
  font-family: $font-lato;
  font-weight: 600;
}

.ant-picker-cell .ant-picker-cell-inner {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
}

.ant-picker-cell {
  color: rgba(0, 0, 0, 0.3);
}

.ant-picker-content th {
  color: rgba(0, 0, 0, 0.5);
}

.ant-picker-cell-in-view {
  color: #000000;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: $green-darker;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: $green-darker;
}

.ant-picker-header-view {
  font-size: 14px;
  font-weight: 600;
  font-family: $font-opensans;
}

.ant-picker-header button {
  outline: none;

  &:hover {
    color: $green-darker;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: #ffffff;
  // background: #F7F6F3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: 14px;
  line-height: 15px;
  box-shadow: none !important;
  color: #000000;
  height: 35px;
}

.btn-none {
  .ant-select-selector {
    border: 0 !important;
  }

  .ant-select-selection-item {
    text-align: right;
    top: 2px;
    margin-right: 10px;
    font-family: $font-opensans;
    font-weight: bold;
  }

  &:hover {
    background-color: transparent !important;
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $green-darker;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgb(25 87 98 / 0.1);
  color: #000000;
  font-weight: 400;
}

.select-icon {
  color: #000;
  // top: -7px;
  top: -4px;
  position: absolute;
  right: -7px;
}

.ant-picker-time-panel-cell-inner {
  text-transform: uppercase;
}

.ant-select-item-option-content {
  font-family: $font-lato;
  font-size: 14px;
}

.ant-select-multiple .ant-select-selection-item {
  background: rgba(25, 87, 98, 0.2);
  border-radius: 20px;
  border: 0;
  font-family: $font-lato;
  font-size: 14px;
  color: #000000;
  &.ant-select-selection-item-disabled {
    background: rgba(25, 87, 98, 0.1);
  }
}

.ant-select-multiple .ant-select-selector {
  padding: 2.5px 4px;
  border-radius: 3px;
}

.ant-rate {
  font-size: 17px;
}

.large {
  .ant-rate {
    font-size: 24px;
  }
}

.ant-input-affix-wrapper {
  padding: 5.5px 11px;
  border-radius: 3px;
}

.form-control.is-valid {
  background-image: none;
}

.ant-select-selection-placeholder {
  font-family: $font-lato;
}

.ant-upload-list-item {
  margin-top: 7px;
  cursor: pointer;

  &:hover .ant-upload-list-item-info {
    color: $green-darker;
    background-color: transparent;
  }

  &:first-child {
    margin-top: 15px;
  }
}

.ant-upload-list-item-info {
  padding-left: 0;
}

.ant-pagination-prev,
.ant-pagination-next {
  line-height: 6px !important;
}

.custom-slider {
  .ant-slider-step,
  .ant-slider-track,
  .ant-slider-rail {
    height: 20px;
    // background: rgba(25, 87, 98, 0.3);
    background: transparent !important;
    border-radius: 35px;
    top: 0;
  }

  .ant-slider-dot {
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 4px;
    border: 2px solid #195762;
  }

  .ant-slider-handle {
    opacity: 0;
  }

  .ant-slider-mark-text {
    transform: translate(-50%, -60%) !important;
    opacity: 0;

    &:hover {
      opacity: 0.5;
    }

    &.active {
      opacity: 1;
    }
  }

  .slider-icon {
    border: 2px solid #ffffff;
    border-radius: 8px;
  }

  .slider-box {
    padding: 0px 14px;
    height: 20px;
    background: rgba(25, 87, 98, 0.3);
    border-radius: 35px;
  }

  &.small {
    .slider-box {
      max-width: 290px;
      height: 16px;
      padding: 0px 6px;
    }

    .slider-icon {
      width: 26px;
    }

    .ant-slider-step,
    .ant-slider-track,
    .ant-slider-rail {
      height: 16px;
    }

    .ant-slider-mark {
      top: 11px;
    }
  }
}

.box-empty {
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 3px;
  margin: 0;
  margin-bottom: 30px;
}

.avt-normal {
  .ant-avatar {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
  }
}

.avt-small {
  .ant-avatar {
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}

.none-space {
  .ant-rate-star {
    margin-right: 0px !important;
  }

  .ant-rate {
    font-size: 13px;
  }
}

.job-information {
  .ant-tabs-nav {
    margin-left: 12% !important;
    height: 46px;
  }

  .ant-tabs-tab {
    padding: 0.5rem 1rem;
  }
}

.ant-tabs-tab {
  margin: 0 !important;
  text-align: center;
  justify-content: center !important;
  border-bottom: 2px solid #000000;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
  bottom: 4px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
}

.ant-tabs-tab-btn {
  color: #000000 !important;
}

.clean-anticon {
  .anticon {
    vertical-align: inherit !important;
  }
}

.ant-tabs {
  height: 100%;
}

.big-star {
  .ant-rate {
    font-size: 24px;
  }
}

.bigger-star {
  .ant-rate {
    font-size: 35px;
  }
}

.aluha-mention {
  &.rte-autocomplete {
    max-height: 100px;
    overflow-y: auto;
    scrollbar-width: thin;
  }
}

.ant-avatar {
  background: $green-dark;
}

.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: $green-darker;
}

.dropdown-new {
  padding: 8px;
  font-size: 12px;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(25, 87, 98, 0.3);
}

.small {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
    font-size: 12px;
    border: 1px solid;
    padding-right: 22px;
  }

  .ant-select-selection-placeholder {
    line-height: 27px !important;
    font-weight: 600;
    color: #000000;
    font-family: $font-opensans;
  }

  .ant-select-arrow {
    .mat-icon {
      font-size: 14px;
      padding: 7.5px;
      top: -9px;
      right: -10px;
      border-left: 1px solid;
    }
  }

  .ant-select-clear {
    top: -2px;
    right: -5px;
  }

  .ant-input-affix-wrapper {
    line-height: 10px !important;
  }

  .ant-picker-range {
    height: 30px;
    font-size: 12px;
    border: 1px solid;
    padding-right: 22px;
    top: 1px;

    .select-icon {
      font-size: 14px;
      padding: 7.5px;
      top: 0px;
      right: 0px;
      border-left: 1px solid;
    }

    .ant-picker-input > input {
      line-height: 16px !important;
      text-align: center;
    }

    .ant-picker-separator {
      top: -4px;
      color: $black;
    }

    .anticon-close-circle {
      top: -19px;
      position: relative;
      right: -20px;
    }
  }
}

.ant-select-dropdown {
  min-width: 150px;
}

.w-large {
  .ant-select-dropdown {
    min-width: 250px;
  }
}

.multi {
  .ant-avatar {
    line-height: 28px;
    border-radius: 50%;
    border: 2px solid #fafafa;
    font-size: 12px;
    width: 30px;
    height: 30px;
    position: relative;

    & ~ .ant-avatar {
      margin-left: -10px;
    }

    & ~ .avt-circle {
      margin-left: -10px;
    }

    // &.normal {
    //   font-size: 10px;
    // }
  }

  .icon-final {
    &.ant-avatar {
      background: rgb(0 0 0 / 60%);
      padding-left: 4px;
    }
  }

  .avt-circle {
    line-height: 28px;
    border-radius: 50%;
    border: 2px solid #fafafa;
    font-size: 12px;
    // width: 29px !important;
    // height: 29px !important;
    background: #d9d9d9;
    position: relative;

    & ~ .avt-circle {
      margin-left: -10px;
    }

    & ~ .ant-avatar {
      margin-left: -10px;
    }
  }
}

.ant-input-affix-wrapper > input.ant-input {
  line-height: 15px;
}

.custom-over {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.box-cover-img {
  // width: 350px;
  height: 120px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 116px;
  }

  img {
    display: initial;
    object-fit: cover;
    width: 100%;
    height: 116px;
  }
}

.box-list-upload {
  .ant-upload-list-item {
    width: 70px;
    height: 70px;
    margin-right: 10px;
    display: inline-block;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 0;
    margin-top: 0px;
  }

  .ant-upload.ant-upload-select-picture-card {
    width: 69px;
    height: 69px;
    background: #ffffff;
    border: 1px dashed #000000;
    border-radius: 3px;
    display: inline-block;
    margin-top: 1px;
  }
}

.avatar-uploader {
  .ant-upload {
    flex-direction: column !important;
    font-family: 'Open Sans';
    font-size: 12px !important;
    font-weight: 600;
  }
}

.ant-picker-range-separator + .ant-picker-input {
  padding-right: 10px;
}

.loading-spin {
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.comment {
  .tox-editor-container {
    padding-right: 30px;
  }

  .tox-toolbar__group {
    position: absolute;
    right: 0;
  }

  .tox-editor-header {
    display: initial;
  }
}

.ant-rate-star:not(:last-child) {
  margin-right: 2px;
}

.ant-avatar-string {
  position: initial;
}

.ant-avatar {
  &.small {
    font-size: 10px;
  }
}

.tooltip-avt {
  .ant-tooltip-inner {
    padding: 2px;
    background-color: #ffffff;
  }

  .ant-tooltip-arrow-content {
    background-color: #ffffff;
  }
}

// .tox-editor-header {
//   display: none;
// }

// .tox-editor-container {
//   &:hover {
//     .tox-editor-header {
//       display: block;
//     }
//   }
// }

.ant-picker-input > input[disabled] {
  background-color: #f5f5f5 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $black;
  border-color: $black;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: $black;
}

.ant-radio-checked .ant-radio-inner,
.ant-radio-checked::after,
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: $black;
}

.ant-radio-inner::after {
  background-color: $black;
}

.full-tab {
  .ant-tabs-tab,
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    padding-top: 12px;
  }
}

.ant-empty-image {
  height: 30px;
  margin-top: 60px;

  img {
    width: 30px;
  }
}

.ant-empty-description {
  color: $black-light;
}

.ant-empty-footer {
  margin-bottom: 60px;
}

.miss-score-tooltip {
  max-width: initial;
}

.miss-core-btn {
  min-width: 70px;
  display: block;
}

.ant-pagination-item:focus,
.ant-pagination-item:hover,
.ant-pagination-next:hover .ant-pagination-item-link {
  border-color: $green-darker;
  color: $green-darker;
  transition: all 0.3s;
}

.ribbon {
  .ant-picker {
    width: auto;
    margin-left: 15px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #ffffff;
    border-radius: 0;
    padding-bottom: 0px;
    padding: 0;
    min-width: 140px;
  }

  .ant-picker-input > input {
    background-color: transparent !important;
    color: #ffffff;
  }

  .ant-picker-suffix {
    color: #ffffff;

    .mat-icon-outline {
      font-size: 15px;
    }
  }

  & ~ .modal-frame {
    height: calc(100vh - 130px) !important;
  }
}

.probation-time-activity,
.select-time-picker {
  .ant-picker {
    width: auto;
    margin-left: 5px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $green-darker;
    border-radius: 0;
    padding-bottom: 0px;
    padding: 0;
    min-width: 128px;
  }

  .ant-picker-input > input {
    background-color: transparent !important;
    color: $green-darker;
    font-size: 13px;
  }

  .ant-picker-suffix {
    color: $green-darker;

    .mat-icon-outline {
      font-size: 13px;
    }
  }
}
.select-time-picker {
  .ant-picker {
    border-bottom: 1px solid #000000;
  }

  .ant-picker-input > input {
    color: #000000;
  }

  .ant-picker-suffix {
    color: #000000;
  }
}

.probation-time-confirm {
  .mat-icon-outline {
    font-size: 16px;
    color: $green-darker;
  }
}

.ant-btn {
  font-size: 12px;
  font-family: 'Open Sans';
  border-radius: 3px;
  font-weight: 600;
  padding: 6px 12px;
  color: #000000;
  box-shadow: none;
  line-height: 16px;
  border: 1px solid #000000;
  height: 30px;
  min-width: 64px;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    color: initial;
    border-color: initial;
  }

  &:focus {
    color: initial;
    border-color: initial;
  }
}

.ant-btn-primary {
  color: #ffffff;
  background-color: $green-darker;
  border-color: $green-darker;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    color: #ffffff;
    border-color: $green-darker;
    background-color: $green-darker;
  }

  &:focus {
    color: #ffffff;
    border-color: $green-darker;
    background-color: $green-darker;
  }
}

.form-group.small .ant-select-selection-item {
  line-height: 27px !important;
  font-weight: 600;
  color: #000000;
  font-family: 'Open Sans';
  font-size: 12px;
}

.picker-head .ant-picker-range .ant-picker-input > input {
  line-height: 27px !important;
  font-weight: 600;
  color: #000000;
  font-family: 'Open Sans';
  font-size: 12px;
}

.ant-picker-focused,
.ant-picker:hover {
  border-color: $green-darker !important;
}

.ant-picker-range .ant-picker-active-bar {
  background-color: $green-darker;
}

.ant-tooltip-inner {
  background-color: #000000;
  border-radius: 3px;
}

.tox-dialog__footer .tox-button {
  font-size: 12px !important;
  font-family: $font-opensans !important;
  border-radius: 3px !important;
  font-weight: 600 !important;
  padding: 6px 12px !important;
  color: #000000 !important;
  box-shadow: none !important;
  line-height: 16px !important;
  border: 1px solid #000000 !important;
  background-color: transparent !important;
}

.custom-clear {
  .ant-select-clear {
    top: 45%;
    right: 10px;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

.ant-select-clear {
  top: 45%;
  color: rgba(0, 0, 0, 0.6);
}

// .ant-select-arrow {
//   top: 45%;
// }

.joyride-step__container {
  font-family: $font-lato;
  border-radius: 3px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2) !important;
  padding: 15px !important;
  height: auto !important;
  color: $black-light !important;
  max-width: 276px !important;
}

.joyride-step__close {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  border: 1px solid #000000;
  border-radius: 50%;

  svg {
    top: -6px;
    position: relative;
    width: 12px;
    height: 6px;
  }
}

.joyride-step__title {
  color: $green-darker !important;
  font-size: 16px !important;
}

.joyride-step__holder {
  font-size: 14px !important;
}

.joyride-step__body {
  padding-top: 0 !important;
}

.joyride-step__counter {
  font-size: 10px !important;
}

.joyride-button {
  width: 50px;
}

.joyride-step__buttons-container {
  color: #000000;
  position: relative;
  right: -10px;
  cursor: pointer;
}

.joyride-step__prev-container {
  position: relative;
  right: -10px;
}

.joyride-step__done-container {
  padding-left: 10px;
}

.ant-modal-close-x {
  width: 20px;
  height: 20px;
  line-height: 13px;
  margin: 6px;
  font-size: 15px;
}

.has-error .ant-select {
  .ant-select-selector {
    border: 1px solid #ee4e4e !important;
  }
}

.employee-salary {
  .ant-select .ant-select-selector {
    border-color: transparent !important;
  }
  .ant-select-arrow {
    top: 42%;
  }
  &.has-error {
    .form-control-icon {
      transform: translateY(0%) !important;
    }
  }
}
.ant-table-row {
  &.selected {
    background-color: rgba(25, 87, 98, 0.1);
  }
}

.ant-select-multiple .ant-select-selection-search {
  max-width: 100%;
  width: auto !important;
  min-width: 250px;
}
.candidate-information {
  .ant-tabs {
    overflow: initial;
  }
}
.tab-dashboard-fix {
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-nav-wrap-ping-left {
    &::before {
      opacity: 0 !important;
    }
  }
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
  background-color: transparent;
}

.custom-picker {
  .ant-picker-input > input {
    font-weight: 600;
    color: #000;
    font-family: "Open Sans";
    font-size: 12px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #000;
      opacity: 1; /* Firefox */
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #000;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #000;
    }
  }
  .select-icon {
    font-size: 14px;
    padding: 7.5px;
    top: -5px;
    right: -30px;
    border-left: 1px solid;
  }
  .ant-picker {
    padding-top: 5px;
    padding-bottom: 6px;
    padding-right: 30px;
    border: 1px solid;
  }
  .ant-picker-clear {
    right: -36px;
    top: -5px;
    font-size: 12px;
    color: rgba(0,0,0,.65);
  }
}
.ant-modal-body {
  padding: 0;
  height: auto;
}