.hidden {
  display: none !important;
}

.backdrop-container {
  z-index: 9999 !important;
}

.joyride-step__holder {
  z-index: 9999 !important;
}

.aluha-mention {
  z-index: 8000 !important;
}

ul>li {
  /* margin: 10px; */
  /* color: #333; */
  color: #000000;
}

ul>li>strong {
  margin: 10px;
}

.input-group-text {
  line-height: 1.4 !important;
}

.rdt .form-control {
  border-radius: 0.25rem 0 0 0.25rem;
}

.dropdown-menu a {
  cursor: pointer;
  font-size: 13px;
}

.has-error .form-error {
  color: #ed1c24;
}

.ngk-group {
  margin-bottom: 30px;
}

.cursor-wait {
  cursor: wait;
}

.state-error {
  color: #ed1c24;
  margin: 5px 0 0 0;
  display: block;
  font-size: 13px;
  text-align: left;
}

/* .has-error {
  text-align: left;
} */

.has-error .ngk-is-invalid {
  border: 1px solid #EE4E4E !important;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
}

.has-error .ng-invalid nz-input-group {
  border: 1px solid #EE4E4E !important;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
}

.has-error .ant-select {
  /* border: 1px solid #EE4E4E !important; */
  background-repeat: no-repeat;
  border-radius: 3px;
}

.has-error .ant-picker {
  border: 1px solid #EE4E4E !important;
  background-repeat: no-repeat;
}

.has-error .text-error {
  color: #EE4E4E;
  font-size: 12px;
  float: left;
  margin-bottom: 15px;
  display: block;
  width: 100%;
}

.invalid-input {
  border-left: EE4E4E solid 3px;
  /* background: #b760602e; */
}

/* ul li.active,
ul li.active a {
    color: #00b5b8 !important;
} */

.dropdown-content {
  height: 300px;
  overflow: auto;
  overflow-x: hidden;
}

.table-bt0 tr td {
  border-top: none !important;
}

.table-pd4 tr td {
  padding: 4px !important;
}

.text-transform {
  text-transform: inherit !important;
}

/* CSS Clear ALL*/

.p0 {
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.mr0 {
  margin-right: 0 !important;
}

.ml0 {
  margin-left: 0 !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mt0 {
  margin-top: 0 !important;
}

/*===============================================
  C. Text Helpers
================================================= */

.text-uppercase {
  text-transform: uppercase !important;
}

.fw200 {
  font-weight: 200 !important;
}

.fw300 {
  font-weight: 300 !important;
}

.fw400 {
  font-weight: 400 !important;
}

.fw600 {
  font-weight: 600 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.fs3 {
  font-size: 4px !important;
}

.fs4 {
  font-size: 4px !important;
}

.fs5 {
  font-size: 5px !important;
}

.fs6 {
  font-size: 6px !important;
}

.fs7 {
  font-size: 7px !important;
}

.fs8 {
  font-size: 8px !important;
}

.fs9 {
  font-size: 9px !important;
}

.fs10 {
  font-size: 10px !important;
}

.fs11 {
  font-size: 11px !important;
}

.fs12 {
  font-size: 12px !important;
}

.fs13 {
  font-size: 13px !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs15 {
  font-size: 15px !important;
}

.fs16 {
  font-size: 16px !important;
}

.fs17 {
  font-size: 17px !important;
}

.fs18 {
  font-size: 18px !important;
}

.fs19 {
  font-size: 19px !important;
}

.fs20 {
  font-size: 20px !important;
}

.fs22 {
  font-size: 22px !important;
}

.fs24 {
  font-size: 24px !important;
}

.fs26 {
  font-size: 26px !important;
}

.fs28 {
  font-size: 28px !important;
}

.fs30 {
  font-size: 30px !important;
}

.fs35 {
  font-size: 35px !important;
}

.fs40 {
  font-size: 40px !important;
}

.fs45 {
  font-size: 45px !important;
}

.fs50 {
  font-size: 50px !important;
}

.fs60 {
  font-size: 60px !important;
}

.fs70 {
  font-size: 70px !important;
}

.fs80 {
  font-size: 80px !important;
}

.fs90 {
  font-size: 90px !important;
}

.fs100 {
  font-size: 100px !important;
}

/*===============================================
  D. Padding Helpers
================================================= */

.pn-initial {
  padding: initial !important;
}

.pn {
  padding: 0 !important;
}

.p1 {
  padding: 1px !important;
}

.p2 {
  padding: 2px !important;
}

.p3 {
  padding: 3px !important;
}

.p4 {
  padding: 4px !important;
}

.p5 {
  padding: 5px !important;
}

.p6 {
  padding: 6px !important;
}

.p7 {
  padding: 7px !important;
}

.p8 {
  padding: 8px !important;
}

.p10 {
  padding: 10px !important;
}

.p12 {
  padding: 12px !important;
}

.p15 {
  padding: 15px !important;
}

.p20 {
  padding: 20px !important;
}

.p25 {
  padding: 25px !important;
}

.p30 {
  padding: 30px !important;
}

.p35 {
  padding: 35px !important;
}

.p40 {
  padding: 40px !important;
}

.p50 {
  padding: 50px !important;
}

.ptn {
  padding-top: 0 !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.prn {
  padding-right: 0 !important;
}

.pr5 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pbn {
  padding-bottom: 0 !important;
}

.pb5 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pln {
  padding-left: 0 !important;
}

.pl5 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl50 {
  padding-left: 50px !important;
}

/* Axis Padding (both top/bottom or left/right) */

.pv5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pv8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pv15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pv20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pv25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pv30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pv40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pv50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.ph5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ph8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.ph10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ph15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.ph20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.ph25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ph30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.ph40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.ph50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

/* responsive padding helpers */

@media (min-width: 1140px) {
  .ptn-lg {
    padding-top: 0 !important;
  }
  
  .prn-lg {
    padding-right: 0 !important;
  }
  
  .pbn-lg {
    padding-bottom: 0 !important;
  }
  
  .pln-lg {
    padding-left: 0 !important;
  }
  
  .pt5-lg {
    padding-top: 5px !important;
  }
  
  .pr5-lg {
    padding-right: 5px !important;
  }
  
  .pb5-lg {
    padding-bottom: 5px !important;
  }
  
  .pl5-lg {
    padding-left: 5px !important;
  }
}

@media (min-width: 992px) {
  .ptn-md {
    padding-top: 0 !important;
  }
  
  .prn-md {
    padding-right: 0 !important;
  }
  
  .pbn-md {
    padding-bottom: 0 !important;
  }
  
  .pln-md {
    padding-left: 0 !important;
  }
  
  .pt5-md {
    padding-top: 5px !important;
  }
  
  .pr5-md {
    padding-right: 5px !important;
  }
  
  .pb5-md {
    padding-bottom: 5px !important;
  }
  
  .pl5-md {
    padding-left: 5px !important;
  }
}

/*===============================================
  E. Margin Helpers
================================================= */

/* margin center helper */

.mauto {
  margin-left: auto;
  margin-right: auto;
}

.mn {
  margin: 0 !important;
}

.m1 {
  margin: 1px !important;
}

.m2 {
  margin: 2px !important;
}

.m3 {
  margin: 3px !important;
}

.m4 {
  margin: 4px !important;
}

.m5 {
  margin: 5px !important;
}

.m8 {
  margin: 8px !important;
}

.m10 {
  margin: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.m25 {
  margin: 25px !important;
}

.m30 {
  margin: 30px !important;
}

.m35 {
  margin: 35px !important;
}

.m40 {
  margin: 40px !important;
}

.m50 {
  margin: 50px !important;
}

.mtn {
  margin-top: 0 !important;
}

.mt3 {
  margin-top: 3px !important;
}

.mt5 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt17 {
  margin-top: 18px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mrn {
  margin-right: 0 !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mbn {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mln {
  margin-left: 0 !important;
}

.ml5 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml50 {
  margin-left: 50px !important;
}

/* Axis Margins (both top/bottom or left/right) */

.mv5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mv10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mv15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mv20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mv25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mv30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mv40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mv50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mv70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mh5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mh10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mh15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mh20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mh25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mh30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mh40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mh50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}

.mh70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}

/* Negative Margin Helpers */

.mtn5 {
  margin-top: -5px !important;
}

.mtn10 {
  margin-top: -10px !important;
}

.mtn15 {
  margin-top: -15px !important;
}

.mtn20 {
  margin-top: -20px !important;
}

.mtn30 {
  margin-top: -30px !important;
}

.mrn5 {
  margin-right: -5px !important;
}

.mrn10 {
  margin-right: -10px !important;
}

.mrn15 {
  margin-right: -15px !important;
}

.mrn20 {
  margin-right: -20px !important;
}

.mrn30 {
  margin-right: -30px !important;
}

.mbn5 {
  margin-bottom: -5px !important;
}

.mbn10 {
  margin-bottom: -10px !important;
}

.mbn15 {
  margin-bottom: -15px !important;
}

.mbn20 {
  margin-bottom: -20px !important;
}

.mbn30 {
  margin-bottom: -30px !important;
}

.mln5 {
  margin-left: -5px !important;
}

.mln10 {
  margin-left: -10px !important;
}

.mln15 {
  margin-left: -15px !important;
}

.mln20 {
  margin-left: -20px !important;
}

.mln30 {
  margin-left: -30px !important;
}

/* Vertical Negative Margin "mv" + "n" + "x" */

.mvn5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.mvn10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.mvn15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.mvn20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.mvn30 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

/* Horizontal Negative Margin "mh" + "n" + "x" */

.mhn5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.mhn10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.mhn15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.mhn20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.mhn30 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

/*===============================================
  F. LineHeight Helpers
================================================= */

.lh0 {
  line-height: 0 !important;
}

.lh5 {
  line-height: 5px !important;
}

.lh10 {
  line-height: 10px !important;
}

.lh15 {
  line-height: 15px !important;
}

.lh20 {
  line-height: 20px !important;
}

.lh25 {
  line-height: 25px !important;
}

.lh30 {
  line-height: 30px !important;
}

.lh35 {
  line-height: 35px !important;
}

.lh40 {
  line-height: 40px !important;
}

/*===============================================
  G. Border Helpers
================================================= */

/* Border Disables */

.br-l-n {
  border-left: none !important;
}

.br-r-n {
  border-right: none !important;
}

.br-t-n {
  border-top: none !important;
}

.br-b-n {
  border-bottom: none !important;
}

.br-n {
  border: none !important;
}

.br-v-n {
  border-top: none !important;
  border-bottom: none !important;
}

.br-h-n {
  border-left: none !important;
  border-right: none !important;
}

/* Default Borders */

.br-a {
  border: 1px solid #eeeeee !important;
}

.br-l {
  border-left: 1px solid #eeeeee !important;
}

.br-r {
  border-right: 1px solid #eeeeee !important;
}

.br-t {
  border-top: 1px solid #eeeeee !important;
}

.br-b {
  border-bottom: 1px solid #eeeeee !important;
}

/* Border Style */

.br-dashed {
  border-style: dotted !important;
}

/* Border Radius */

.br0 {
  border-radius: 0px !important;
}

.br1 {
  border-radius: 1px !important;
}

.br2 {
  border-radius: 2px !important;
}

.br3 {
  border-radius: 3px !important;
}

.br4 {
  border-radius: 4px !important;
}

.br6 {
  border-radius: 6px !important;
}

.br8 {
  border-radius: 8px !important;
}

.br12 {
  border-radius: 12px !important;
}

.br24 {
  border-radius: 24px !important;
}

.br64 {
  border-radius: 64px !important;
}

.br50-percent {
  border-radius: 50% !important;
}

/* Border Width */

.bw1 {
  border-width: 1px !important;
}

.bw2 {
  border-width: 2px !important;
}

.bw3 {
  border-width: 3px !important;
}

.bw4 {
  border-width: 4px !important;
}

.bw5 {
  border-width: 5px !important;
}

.bw8 {
  border-width: 8px !important;
}

.bw10 {
  border-width: 10px !important;
}

.bw15 {
  border-width: 15px !important;
}

/*Border Colors Generate contextual modifier classes for colorizing the alert.
*/

.br-white {
  border-color: #ffffff !important;
}

.br-lighter {
  border-color: #eeeeee !important;
}

.br-light {
  border-color: #e7e7e7 !important;
}

.br-grey {
  border-color: #d9d9d9 !important;
}

.br-greyer {
  border-color: #cccccc !important;
}

.br-dark {
  border-color: #3b3f4f !important;
}

.br-dark-light {
  border-color: #51566c !important;
}

.br-primary {
  border-color: #4a89dc !important;
}

.br-primary-light {
  border-color: #75a5e4 !important;
}

.br-info {
  border-color: #3bafda !important;
}

.br-info-light {
  border-color: #66c1e2 !important;
}

.br-success {
  border-color: #70ca63 !important;
}

.br-success-light {
  border-color: #93d789 !important;
}

.br-warning {
  border-color: #f6bb42 !important;
}

.br-warning-light {
  border-color: #f8cd73 !important;
}

.br-danger {
  border-color: #e9573f !important;
}

.br-danger-light {
  border-color: #ee7f6d !important;
}

.br-alert {
  border-color: #967adc !important;
}

.br-alert-light {
  border-color: #b6a2e7 !important;
}

.br-system {
  border-color: #37bc9b !important;
}

.br-system-light {
  border-color: #58ceb1 !important;
}

.br-tp-top {
  border-top-color: rgba(0, 0, 0, 0.1);
}

.br-tp-right {
  border-right-color: rgba(0, 0, 0, 0.1);
}

.br-tp-left {
  border-left-color: rgba(0, 0, 0, 0.1);
}

/*===============================================
  H. Max Width Helpers
================================================= */

.fluid-width {
  width: 100% !important;
}

.mw10 {
  max-width: 10px !important;
}

.mw20 {
  max-width: 20px !important;
}

.mw30 {
  max-width: 30px !important;
}

.mw35 {
  max-width: 35px !important;
}

.mw40 {
  max-width: 40px !important;
}

.mw45 {
  max-width: 45px !important;
}

.mw50 {
  max-width: 50px !important;
}

.mw60 {
  max-width: 60px !important;
}

.mw80 {
  max-width: 80px !important;
}

.mw100 {
  max-width: 100px !important;
}

.mw140 {
  max-width: 140px !important;
}

.mw160 {
  max-width: 160px !important;
}

.mw180 {
  max-width: 180px !important;
}

.mw200 {
  max-width: 200px !important;
}

.mw240 {
  max-width: 240px !important;
}

.mw280 {
  max-width: 280px !important;
}

.mw320 {
  max-width: 320px !important;
}

.mw400 {
  max-width: 400px !important;
}

.mw450 {
  max-width: 450px !important;
}

.mw500 {
  max-width: 500px !important;
}

.mw600 {
  max-width: 600px !important;
}

.mw700 {
  max-width: 700px !important;
}

.mw800 {
  max-width: 800px !important;
}

.mw900 {
  max-width: 900px !important;
}

.mw1000 {
  max-width: 1000px !important;
}

.mw1100 {
  max-width: 1100px !important;
}

.mw1200 {
  max-width: 1200px !important;
}

/*Regular Width Helpers. Primarily used
on tables, feel free to add more*/

.w20 {
  width: 20px !important;
}

.w30 {
  width: 30px !important;
}

.w40 {
  width: 40px !important;
}

.w50 {
  width: 50px !important;
}

.w75 {
  width: 75px !important;
}

.w100 {
  width: 100px !important;
}

.w125 {
  width: 125px !important;
}

.w150 {
  width: 150px !important;
}

.w175 {
  width: 175px !important;
}

.w200 {
  width: 200px !important;
}

.w225 {
  width: 225px !important;
}

.w250 {
  width: 250px !important;
}

.w300 {
  width: 300px !important;
}

.w320 {
  width: 320px !important;
}

.w350 {
  width: 350px !important;
}

.w400 {
  width: 400px !important;
}

.w450 {
  width: 450px !important;
}

.w45ps {
  width: 45%;
}

.mnw20 {
  min-width: 20px !important;
}

.mnw30 {
  min-width: 30px !important;
}

.mnw40 {
  min-width: 40px !important;
}

.mnw50 {
  min-width: 50px !important;
}

.mnw75 {
  min-width: 75px !important;
}

.mnw100 {
  min-width: 100px !important;
}

.mnw125 {
  min-width: 125px !important;
}

.mnw150 {
  min-width: 150px !important;
}

.mnw175 {
  min-width: 175px !important;
}

.mnw200 {
  min-width: 200px !important;
}

.mnw250 {
  min-width: 250px !important;
}

.mnw300 {
  min-width: 300px !important;
}

.mnw500 {
  min-width: 500px !important;
}

.mnw700 {
  min-width: 700px !important;
}

.mnw1000 {
  min-width: 1000px !important;
}

/* custom scroll bar */

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #cecdd3;
  /*-webkit-border-radius: 3px;
    border-radius: 3px;*/
}

/* Handle */

::-webkit-scrollbar-thumb {
  /*-webkit-border-radius: 6px;*/
  /*border-radius: 6px;*/
  background: #D7D7D6;
  -webkit-box-shadow: inset 0 0 6px #cccccc;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #D7D7D6;
}

/* custom modal */

.aluha-modal .aluha-no-header .modal-body {
  top: 0px;
}

.aluha-modal .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 0;
  box-shadow: none;
}

.aluha-modal .modal-header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border: 0;
  border-bottom: 1px solid #e4e7f3;
}

.aluha-modal .modal-body {
  position: absolute;
  top: 50px;
  bottom: 0px;
  width: 100%;
  font-weight: 300;
  overflow: auto;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.aluha-modal .modal-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: #f1f3f5;
  z-index: 1;
  min-height: 50px;
}

.aluha-modal.modal {
  top: 5px;
  left: 10px;
  bottom: 5px;
  right: 5px;
}

.aluha-modal .modal-content {
  border: none;
  border: none;
  border-radius: 0;
}

.aluha-modal .modal-header .close {
  /* margin-top: 5px; */
  font-size: 35px;
  margin-right: 5px;
  margin-top: -25px;
}

.aluha-modal.modal-fullscreen .modal-dialog {
  margin: 0;
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  position: absolute;
}

/* modal 80% */

.aluha-modal.modal-80 .modal-dialog {
  margin: 0 auto;
  width: 80% !important;
  max-width: 80% !important;
  height: 100% !important;
}

.aluha-modal.modal-60 .modal-dialog {
  margin: 0 auto;
  width: 60% !important;
  max-width: 60% !important;
  height: 100% !important;
}

.aluha-modal.modal-70 .modal-dialog {
  margin: 0 auto;
  width: 70% !important;
  max-width: 70% !important;
  height: 100% !important;
}

.aluha-modal.modal-40 .modal-dialog {
  margin: 0 auto;
  width: 40% !important;
  max-width: 40% !important;
  height: 70% !important;
}

/* < 80 no se chay*/

@media only screen and (max-height: 800px) {
  .aluha-modal.modal-40 .modal-dialog {
    height: 100% !important;
  }
}

/*end modal 80% */

@media only screen and (max-width: 768px) {
  .aluha-modal.modal-80 .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    /* position: initial; */
    padding-top: 25px;
  }
  
  .aluha-modal.modal-70 .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
    margin-right: auto;
    padding-top: 25px;
    margin-left: auto;
    /* position: initial; */
  }
  
  .aluha-modal.modal-60 .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 25px;
    /* position: initial; */
  }
  
  .aluha-modal.modal-40 .modal-dialog {
    width: 100% !important;
    max-width: 100% !important;
    height: 70% !important;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 25px;
    /* position: initial; */
  }
  
  .aluha-modal.modal {
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
  }
}

@media (min-width: 992px) {
  .aluha-modal.modal-80 .modal-dialog {
    width: 80% !important;
    max-width: 80% !important;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 25px;
    /* position: initial; */
  }
  
  .aluha-modal.modal-70 .modal-dialog {
    width: 70% !important;
    max-width: 70% !important;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 25px;
    /* position: initial; */
  }
  
  .aluha-modal.modal-60 .modal-dialog {
    width: 60% !important;
    max-width: 60% !important;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 25px;
    /* position: initial; */
  }
  
  .aluha-modal.modal-60 .modal-dialog {
    width: 40% !important;
    max-width: 40% !important;
    margin: 0;
    margin-right: auto;
    margin-left: auto;
    padding-top: 25px;
    /* position: initial; */
  }
}

.modal-title {
  font-size: 18px;
  padding-left: 10px;
}

.modal-header {
  padding: 7px !important;
}

/* end modal */

.form-group-eye {
  position: relative;
}

.field-icon {
  position: absolute;
  right: 10px;
  top: 38px;
}

.field-icon i {
  color: #e1e1d0;
}

.field-icon.active i,
.field-icon:hover i {
  color: #6a5db6;
}

.group-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.group-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.group-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 30px !important;
  width: 30px !important;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */

.group-radio:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.group-radio input:checked~.checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.group-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.group-radio input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.group-radio .checkmark:after {
  left: 12px;
  top: 7px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-group .pretty {
  font-size: 18px;
}

/* Style avatar user
/* ---------------------------------------------------------- */

.image-uploader {
  position: relative;
  overflow: hidden;
  width: 180px;
  height: 180px;
  max-width: 500px;
  background: #f6f7f8;
  border-radius: 4px;
  color: #808284;
  text-align: center;
}

.image-uploader .description {
  font-size: 1.6rem;
}

.image-uploader a {
  color: #7d878a;
  text-decoration: none;
}

.image-uploader a:hover {
  color: #242628;
}

.image-uploader .image-upload,
.image-uploader .image-url {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  padding: 10px;
  color: #7d878a;
  text-decoration: none;
  font-size: 14px;
  line-height: 12px;
}

.image-uploader .image-upload:hover,
.image-uploader .image-url:hover {
  cursor: pointer;
}

.image-uploader .btn-green {
  position: relative;
  z-index: 700;
  display: inline-block;
  margin-top: 10px;
  color: #fff;
}

.image-uploader input.main {
  position: absolute;
  right: 0;
  margin: 0;
  font-size: 23px;
  opacity: 0;
  cursor: pointer;
  -webkit-transform: scale(14);
  transform: scale(14);
  -webkit-transform-origin: right;
  transform-origin: right;
  direction: ltr;
}

.image-uploader input.main.right {
  right: 9999px;
  height: 0;
}

.image-uploader input.url {
  margin: 0 0 10px 0;
  padding: 9px 7px;
  outline: 0;
  background: #fff;
  vertical-align: middle;
  font: -webkit-small-control;
  font-size: 1.4rem;
}

.image-uploader input.url+.btn.btn-blue {
  color: #fff;
}

.image-uploader .fileupload-loading {
  top: 50%;
  display: block;
  margin: 0 auto;
  width: 35px;
  height: 28px;
  background-size: contain;
}

.image-uploader .failed {
  position: relative;
  font-size: 16px;
}

.image-uploader .bar {
  height: 12px;
  background: #5ba4e5;
}

.image-uploader .bar.fail {
  background: #e25440;
}

.image-uploader img {
  width: 100%;
  height: 100%;
}

.image-cancel {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 300;
  display: block;
  padding: 8px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  box-shadow: rgba(255, 255, 255, 0.2) 0 0 0 1px;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  line-height: 10px;
}

.image-cancel:hover {
  background: #7a6fbe;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

.image-uploader .image-plus {
  margin-top: calc(50% - 20px);
  margin-bottom: calc(50% - 20px);
}

.image-uploader .image-plus i {
  font-size: 40px;
}

.Wishlist .ant-transfer-operation button {
  line-height: 0 !important;
  width: 70px;
  text-align: center;
}

.Wishlist .ant-transfer-operation button span {
  margin: 0;
}

.Wishlist .ant-transfer-list-header {
  background: #7a6fbe;
  color: #fff;
}

.Wishlist .ant-transfer-list-header .ant-transfer-list-header-title {
  font-weight: bold;
}

.Wishlist .ant-transfer-list-body {
  background: #f0f0f0;
}

p.tagcheck {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}
