.app-header {
  overflow: hidden;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 0 20px;
  .row {
    height: 70px;
  }
  &__logo {
    max-width: 120px;
  }
  &__menu {
    text-transform: capitalize;
    &--item {
      padding: 0 18px;
      font-weight: 600;
      font-family: $font-opensans;
      &:last-child {
        padding-right: 0;
      }
      &.active {
        color: $color-primary;
      }
    }
  }
}