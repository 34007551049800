// Colours
$green: #38ad51;
$green-dark: #4abc98;
$green-darker: #195762;
$green-light: #d7efdc;
$gray-light: #f7f6f3;
$gray: #d7d7d6;
$gray-dark: #989898;
$black: #000000;
$white: #ffffff;
$blue-light: #d6e4f7;
$blue: #b8dfe6;
$pink: #f5d5e5;
$yellow: #eeeeaf;
$red: #ff0000;
$red-light: #ee4e4e;
$orange: #f9e0d4;
$orange-dark: #f7440b;
$black-light: rgba(0, 0, 0, 0.5);
$black-lighter: rgba(0, 0, 0, 0.1);

$size-xxxs: 2px;
$size-xxs: 5px;
$size-xs: 10px;
$size-sm: 15px;
$size-md: 25px;
$size-lg: 30px;
$size-xl: 40px;
$size-xxl: 50px;
$mq-mobile-min: 320px;
$mq-mobile-max: 767px;
$mq-tablet-min: 768px;
$mq-tablet-mid: 992px;
$mq-tablet-max: 1024px;
$mq-desktop-min: 1025px;
$mobile-prefix: 'mobi';
$tablet-prefix: 'tabl';
$desktop-prefix: 'desk';
// Spacing
$size-list: (xxxs, xxs, xs, sm, md, lg, xl, xxl);
$size-num: ($size-xxxs, $size-xxs, $size-xs, $size-sm, $size-md, $size-lg, $size-xl, $size-xxl);
$color-list: (R, B, G, Y, P, O, L, H, V, N, K, M);
$color-list-css: (
  'red',
  'red-light',
  'green-dark',
  'green-darker',
  'green-light',
  'gray',
  'gray-light',
  'gray-dark',
  'black',
  'black-light',
  'green',
  'pink',
  'blue-light',
  'blue',
  'yellow',
  'orange',
  'orange-dark'
);
$color-value: (
  $red,
  $red-light,
  $green-dark,
  $green-darker,
  $green-light,
  $gray,
  $gray-light,
  $gray-dark,
  $black,
  $black-light,
  $green,
  $pink,
  $blue-light,
  $blue,
  $yellow,
  $orange,
  $orange-dark
);
//Font
$font-opensans: 'Open Sans';
$font-lato: 'Lato';
$size-normal: 14px;
$size-alt1: 12px;
$size-alt2: 10px;

$color-primary: $green-darker;
$font-primary: $font-opensans;
$font-second: $font-lato;

.bold {
  font-weight: 600;
  // font-weight: bold;
}

.font-open {
  font-family: $font-opensans;
}

.font-lato {
  font-family: $font-lato;
}

.semi-bold {
  font-weight: 600;
}

.regular {
  font-weight: 400 !important;
}

.opacity-5 {
  opacity: 0.5;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 14px;
  font-weight: normal;
}

ul,
p,
label {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none !important;

  &:hover {
    color: inherit;
  }
}

@for $i from 5 through 50 {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 5 through 40 {
  .lh-#{$i} {
    line-height: #{$i}px !important;
  }
}

@for $i from 1 through 10 {
  .z-#{$i} {
    z-index: #{$i} !important;
  }
}

// @for $i from 1 through length($color-list) {
//   .bg-#{nth($color-list, $i)} {
//     background-color: nth($color-value, $i) !important;
//   }
// }

@for $i from 1 through length($color-list-css) {
  .bg-#{nth($color-list-css, $i)} {
    background-color: nth($color-value, $i) !important;
  }

  .text-#{nth($color-list-css, $i)} {
    color: nth($color-value, $i) !important;
  }

  .border-#{nth($color-list-css, $i)} {
    border: 1px solid nth($color-value, $i) !important;
  }
}

@for $i from 1 through length($size-list) {
  .p-#{nth($size-list, $i)} {
    padding: nth($size-num, $i) !important;
  }

  .p-l-#{nth($size-list, $i)} {
    padding-left: nth($size-num, $i) !important;
  }

  .p-r-#{nth($size-list, $i)} {
    padding-right: nth($size-num, $i) !important;
  }

  .p-lr-#{nth($size-list, $i)} {
    padding-left: nth($size-num, $i) !important;
    padding-right: nth($size-num, $i) !important;
  }

  .p-t-#{nth($size-list, $i)} {
    padding-top: nth($size-num, $i) !important;
  }

  .p-b-#{nth($size-list, $i)} {
    padding-bottom: nth($size-num, $i) !important;
  }

  .p-tb-#{nth($size-list, $i)} {
    padding-top: nth($size-num, $i) !important;
    padding-bottom: nth($size-num, $i) !important;
  }

  .m-#{nth($size-list, $i)} {
    margin: nth($size-num, $i) !important;
  }

  .m-l-#{nth($size-list, $i)} {
    margin-left: nth($size-num, $i) !important;
  }

  .m-r-#{nth($size-list, $i)} {
    margin-right: nth($size-num, $i) !important;
  }

  .m-lr-#{nth($size-list, $i)} {
    margin-left: nth($size-num, $i) !important;
    margin-right: nth($size-num, $i) !important;
  }

  .m-t-#{nth($size-list, $i)} {
    margin-top: nth($size-num, $i) !important;
  }

  .m-b-#{nth($size-list, $i)} {
    margin-bottom: nth($size-num, $i) !important;
  }

  .m-tb-#{nth($size-list, $i)} {
    margin-top: nth($size-num, $i) !important;
    margin-bottom: nth($size-num, $i) !important;
  }
}

.m-t-none {
  margin-top: 0 !important;
}

.m-r-none {
  margin-right: 0 !important;
}

.m-b-none {
  margin-bottom: 0 !important;
}

.m-l-none {
  margin-left: 0 !important;
}

.m-lr-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.m-tb-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-none {
  margin: 0 !important;
}

.p-t-none {
  padding-top: 0 !important;
}

.p-r-none {
  padding-right: 0 !important;
}

.p-b-none {
  padding-bottom: 0 !important;
}

.p-l-none {
  padding-left: 0 !important;
}

.p-lr-none {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-tb-none {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-none {
  padding: 0 !important;
}

.m-t-150 {
  margin-top: 150px;
}
.m-t-120 {
  margin-top: 120px;
}

.border-none {
  border: 0 !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.border-top-none {
  border-top: none !important;
}

.border-right-none {
  border-right: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-left-none {
  border-left: none !important;
}

.pointer {
  cursor: pointer !important;
}

.cursor-move {
  cursor: move !important;
}

.text-underline {
  text-decoration: underline !important;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100% !important;
}

.min-width-50per {
  min-width: 50%;
}

.height-100px {
  height: 100px !important;
}

.full-vh {
  height: 100vh;
}

.min-full-vh {
  min-height: 100vh;
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-5 {
  border-radius: 5px;
}

.max-w1200 {
  max-width: 1200px !important;
}

.max-w1100 {
  max-width: 1100px !important;
}

.max-w950 {
  max-width: 950px !important;
}

.max-w850 {
  max-width: 850px !important;
}

.max-w800 {
  max-width: 800px !important;
}

.max-w750 {
  max-width: 750px !important;
}

.max-w650 {
  max-width: 650px !important;
}

.max-w600 {
  max-width: 600px !important;
}

.max-w450 {
  max-width: 450px !important;
}

.max-w350 {
  max-width: 350px !important;
}

.margin-auto {
  margin: 0 auto;
}

.margin-inherit {
  margin: inherit;
}

.ml-12-percent {
  margin-left: 12% !important;
}

.p-small {
  padding: 2px;
}

button:focus {
  outline: none !important;
}

/* custom scroll bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d7d7d6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d7d7d6;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #d7d7d6;
}
