@import 'react-perfect-scrollbar/dist/css/styles.css';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'ag-grid-community/dist/styles/ag-grid.css';
@import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

.full-window.ant-modal,.full-window .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
   }
.full-window .ant-modal-body {
    height: calc(100vh - 110px);
   }