h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-primary;
  color: #000000;
}

.color-primary {
  color: $color-primary !important;
}

.text-description {
  font-size: 12px;
  color: $black-light;
}

hr {
  height: 2px;
  background: #000000;
  opacity: 0.1;
  border: 0;
}

.icon-scale {
  height: 17px;
}

.limit-modal {
  max-width: 1200px;
  margin: 0 auto;
}

.img-logo-login {
  height: 224px;
}
@media (max-width: 1380px) {
  .img-logo-login {
    height: 220px;
  }
}

.btn {
  font-size: 12px;
  font-family: $font-primary;
  border-radius: 3px;
  font-weight: 600;
  padding: 6px 12px;
  color: #000000;
  box-shadow: none;
  line-height: 16px;
  border: 1px solid #000000 !important;
  // max-height: 30px;

  &.btn-larger {
    font-size: 24px;
    font-weight: 600;
    padding: 16px 30px;
    width: 250px;
  }

  &.btn-medium {
    padding: 6px 12px;
  }
  
  &-none {
    background-color: transparent;
    border: 0;
    outline: none;
    box-shadow: none !important;
    padding-left: 0;
  }

  // &::after {
  //     display: none;
  // }

  .icon {
    margin-right: 6px;
    position: relative;
    top: -1px;

    &.last {
      margin-right: 0;
      margin-left: 6px;
    }
  }

  &-name {
    position: relative;
    top: -8px;
  }

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  &.btn-primary {
    color: #ffffff;
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover {
      background-color: $color-primary;
      border-color: $color-primary;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      box-shadow: none !important;
    }

    &:active {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  &.btn-blue {
    color: #ffffff;
    background-color: #3072AC;
    border-color: #3072AC;
  }

  &.btn-dropdown {
    padding-right: 42px;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: 0.5s all;
    }

    &::before {
      content: '';
      height: 100%;
      border-left: 1px solid rgb(255 255 255 / 64%);
      right: 27px;
    }

    &::after {
      right: 9.5px;
      content: '\f078';
      font-family: 'Font Awesome 5 Free';
      border: 0;
      font-size: 9px;
    }

    &.outline {
      background-color: transparent;
      color: $black;
      border-color: $black;

      &::before {
        border-left: 1px solid $black;
      }
    }

    &.none-outline {
      padding-right: 28px;

      &::before {
        opacity: 0;
      }

      &::after {
        font-size: 11px;
        right: 11px;
      }
    }

    &.small {
      padding-left: 8px;
      padding-top: 1px;
      padding-bottom: 1px;
      background-color: #d7efdc !important;

      &:hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
      }
    }

    &.bg-none {
      background-color: transparent !important;
      padding-right: 30px;
    }

    &.btn-small {
      padding: 3px 8px;
    }

    &.no-arrow {
      padding-right: 8px;

      &::after {
        display: none;
      }
    }

    &.show {
      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &.none-outline {
    background-color: transparent;
    color: $black;
    border-color: transparent;

    &:hover {
      background-color: rgba(25, 87, 98, 0.1);
      box-shadow: none;
    }

    img {
      max-width: 12px;
    }
  }

  &:disabled {
    box-shadow: none !important;
    cursor: initial;
  }
}

.has-error {
  .form-control-icon {
    transform: translateY(-45%);
  }
}

.hide-error {
  .text-error {
    opacity: 0;
  }
}

.err-text {
  color: $red;
  font-size: $size-alt1;
  font-family: $font-second;
  margin-top: 2px;
  display: block;
}

.icon-action {
  width: 40px;
  height: 40px;
  background-color: transparent;
  transition: all 0.2s;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: rgba(25, 87, 98, 0.1);
  }

  &::after {
    display: none;
  }

  &-count {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    z-index: 1;
    background-color: $red-light;
    top: 2px;
    right: 6px;
    font-size: 8px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  &.small {
    width: 30px;
    height: 30px;

    @media (max-width: 1350px) {
      width: 25px;
      height: 25px;
      margin: 2px 0px;
    }
  }
}

.tick-info {
  display: inline-block;
  margin-left: 30px;
  position: relative;
  top: -1px;
  word-break: break-word;
  // &.center {
  //   top: -13px;
  // }
}

.radio-tick {
  margin-left: 25px;
  position: relative;
  top: 7px;
}

.main {
  &-wrapper {
    background-color: #ffffff;
    padding-left: 200px;
  }

  &-content {
    height: calc(100vh - 78px);
    overflow-y: auto;
    font-family: $font-second;
    color: #000000;
    scrollbar-width: thin;
  }
}

.frame {
  &-devide {
  }

  &-left {
    width: 230px;
    display: inline-block;

    @media (max-width: 1300px) {
      width: 190px;
    }
  }

  &-right {
    width: calc(100% - 230px);
    display: inline-block;
    vertical-align: top;

    @media (max-width: 1300px) {
      width: calc(100% - 190px);
    }
  }

  &-menu {
    max-width: 180px;

    &-item {
      cursor: pointer;
      padding: 5px 24px;
      border-left: 4px solid #ffffff;
      transition: all 0.5s;
      font-family: $font-second;
      color: $black-light;

      &.active,
      &.active-custom {
        border-color: $color-primary;
        color: #000000;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.people {
  &-box {
    // Can clear scroll
    height: calc(100vh - 375px);
    overflow-y: auto;
    scrollbar-width: thin;
    padding-right: 5px;

    &.custom-scorecard {
      // height: calc(100vh - 185px);
      height: calc(100vh - 370px);
    }

    /* custom scroll bar */

    &::-webkit-scrollbar {
      width: 5px;
      height: 3px;
    }
  }

  &-list {
    padding-left: 4px;
  }
}

.exp-dropdown {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &-item {
    padding: 6px 12px;
    color: $black-light;
  }
}

.permission-group {
  .row {
    height: 40px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  &.edit {
    .row {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-top: 0;

      &:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }

  .container-check {
    top: -14px;
    left: -10px;

    &::after {
      width: 35px;
      height: 35px;
      left: -9px;
      top: -9px;
    }
  }
}

.job-ans {
  .score-question-item {
    position: relative;
  }
  .container-check {
    left: -10px;
    top: -8px;
  }
  .is-have-expect {
    .container-check {
      .radiomark {
        &::after {
          background-color: $red-light;
        }
      }
      input:checked ~ .radiomark {
        border: 2px solid $red-light;
      }
      input:checked ~ .checkmark {
        background-color: $red-light;
        border: 2px solid $red-light;
      }
    }
  }
  .is-match {
    .container-check {
      .radiomark {
        &::after {
          background-color: $green;
        }
      }
      input:checked ~ .radiomark,
      input:checked ~ .checkmark {
        border: 2px solid $green;
      }
      input:checked ~ .checkmark {
        background-color: $green;
      }
    }
  }
  .count-match {
    position: absolute;
    right: -40px;
  }
}

.modal-content {
  font-family: $font-second;
}

.toast {
  &-box {
    background-color: #000000;
    color: #ffffff;
    border-radius: 3px;
    padding: 10px 35px 10px 20px;
    position: relative;
    // max-width: 640px;

    .mat-icon-outline {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  &-limit {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.remove-over {
  position: absolute;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 12px 13px;
  display: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border: 1px solid;
  font-family: $font-lato;
  border-radius: 3px;
  width: calc(100% - 50px);
  cursor: pointer;
  z-index: 1;
  &.full {
    width: 100%;
  }
}

.bg-none {
  background-color: transparent !important;
}

.score-question {
  // border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &-item {
    padding: 18px 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin-bottom: 8px;
    border-radius: 3px;

    &.is-expand {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

      &.m-b-none {
        box-shadow: none;
      }
    }

    &.m-b-none {
      border-radius: 0;
      border-bottom: 0;

      &:last-child {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
      &:first-child {
        border-radius: 3px;
      }
    }

    &.border-error,
    &.not-arrow {
      box-shadow: none !important;
      .score-question-title {
        &::before {
          opacity: 0 !important;
        }
      }
    }
  }

  &-title {
    position: relative;
    width: 100%;
    cursor: pointer;
    padding-right: 25px;

    &::before {
      font-family: 'Material Icons';
      content: 'expand_more';
      float: right;
      transition: all 0.5s;
      right: 0px;
      position: absolute;
      font-size: 24px;
      top: -3px;
    }

    &.disabled-arrow {
      &::before {
        color: $gray;
      }
    }

    &[aria-expanded='true'] {
      &::before {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    &.showed {
      &::before {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }

    // &.collapsed {
    //   &::before {
    //     -webkit-transform: rotate(180deg);
    //     -moz-transform: rotate(180deg);
    //     transform: rotate(180deg);
    //   }
    // }
  }

  &.qualititative {
    .score-question-item {
      // border: 0;
      // padding-left: 0;
      // padding-right: 0;
      padding-bottom: 0;
      &.is-expand {
        box-shadow: none;
      }
    }
    .score-question-title {
      font-size: 18px;
      &::before {
        display: none;
      }
    }
  }
}

.quantitative {
  .ant-rate {
    font-size: 20px;
    position: relative;
    top: 1px;
  }
}

.custom-scroll {
  /* Track */

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px #ffffff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #d7d7d6;
    -webkit-box-shadow: inset 0 0 6px rgb(25 87 98 / 25%);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: #d7d7d6;
  }
}

.custom-scroll-box {
  /* Track */

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px #ffffff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #d7d7d6;
    -webkit-box-shadow: inset 0 0 6px rgb(25 87 98 / 25%);
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    background: #d7d7d6;
  }
}
.text-version {
  position: absolute;
  bottom: 2px;
  font-size: 11px;
  left: 27px;
  color: $color-primary;
}
.job-actived {
  .job-item {
    border-color: #1a5762 !important;
  }
}
.m-auto {
  margin: 0 auto;
}
.text-error {
  color: $red;
  font-size: 12px;
}
.header {
  &-page {
    height: 78px;
    display: flex;
    align-items: center;
    &.have-bg {
        background-color: #F7F6F3;
    }
    &.pd-left {
      padding-left: 15%;
    }
  }
}
.box-avatar {
  float: left;
  height: 100px;
  width: 100px;
  border: 1px solid #eee;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 80px;
  margin-top: 5px;
  img {
      border-radius: 50%;
      max-width: 100%;
      object-fit: cover;
      width: 100px;
      height: 100px;
  }
}

.border-footer {
  border-top: 1px solid #eee; padding-top: 14px
}