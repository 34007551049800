.aside-left {
  width: 200px;
  padding: 15px;
  background: #f7f6f3;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;

  .menu {
    &-item {
      padding: 10px;
      color: #000000;
      display: flex;
      align-items: center;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        font-weight: 600;
      }

      &.active,
      &.actived {
        font-weight: 600;
        background: rgba(0, 0, 0, 0.05);
      }

      &.fix-end {
        position: absolute;
        bottom: 15px;
      }
    }

    &-name {
      color: #000000 !important;
      outline: none;
    }

    &-icon {
      font-size: 22px;
      margin-right: 14px;
    }

    &-left {
      list-style: none;
      padding-left: unset;
    }
  }

  @media (max-width: 1350px) {
    width: 180px;
  }
}